<!-- eslint-disable max-len -->
<template>
  <div class="selsctView">
    <span class="back_icon" @click="goblack()"></span>
    <div class="selsctViewView_main">
      <div class="dialogShow">
        <div class="form_box">
          <el-form
            label-width="0"
            :model="dataForm"
            ref="dataRef"
            :rules="props.SMreport == 1 ? rules : nmrules"
          >
            <el-row :span="24">
              <p style="border-bottom: 3px solid #c31c1f; width: 80px">
                举报人信息
              </p>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model.trim="dataForm.name"
                    placeholder="请输入姓名"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号" prop="idNumber">
                  <el-input
                    v-model.trim="dataForm.idNumber"
                    placeholder="请输入身份证号"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="性别" prop="sex">
                  <el-select
                    v-model="dataForm.sexNum"
                    placeholder="请选择"
                    @change="changeSEX1"
                  >
                    <el-option
                      v-for="item in SexOptions"
                      :key="item.sexNum"
                      :label="item.label"
                      :value="item.sexNum"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位" prop="company">
                  <el-input
                    v-model.trim="dataForm.company"
                    placeholder="请输入单位"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="职务" prop="duty">
                  <el-input
                    v-model.trim="dataForm.duty"
                    placeholder="请输入职务"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系方式" prop="phone">
                  <el-input
                    maxlength="11"
                    v-model.trim="dataForm.phone"
                    placeholder="请输入联系方式"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <p style="border-bottom: 3px solid #c31c1f; width: 100px">
                被举报人信息
              </p>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="姓名" prop="cvname">
                  <el-input
                    v-model.trim="dataForm.cvname"
                    placeholder="请输入姓名"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别" prop="cvsex">
                  <el-select
                    v-model="dataForm.cvsex"
                    placeholder="请选择"
                    @change="changeSEX2"
                  >
                    <el-option
                      v-for="item in SexOptions1"
                      :key="item.sexNum"
                      :label="item.label"
                      :value="item.sexNum"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="身份证号" prop="cvIdNumber">
                  <el-input
                    maxlength="18"
                    v-model.trim="dataForm.cvIdNumber"
                    placeholder="请输入身份证号"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位" prop="cvCompany">
                  <el-input
                    v-model.trim="dataForm.cvCompany"
                    placeholder="请输入单位"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="职务信息" prop="cvDuty">
                  <el-input
                    v-model.trim="dataForm.cvDuty"
                    placeholder="请输入职务信息"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="附件" prop="enclosure">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    action="https://zj12380.gov.cn/api/importment"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="dataForm.documentFileList"
                    accept=".jpg,.jpeg,.png,.pdf,.doc,docx,.JPG,.JPEG,.PDF,.DOC,.DOCX"
                    :before-upload="beforeUpload"
                    :limit="1"
                  >
                    <el-button slot="trigger" size="small" type="primary"
                      >选取文件</el-button
                    >
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="24">
                <el-form-item label="主要问题" prop="problem">
                  <el-input
                    v-model.trim="dataForm.problem"
                    placeholder="请输入内容"
                    size="small"
                    maxlength="2000"
                    type="textarea"
                    :rows="4"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="认证码" prop="inputNumber">
                  <el-input
                    style="margin-right: 10px"
                    maxlength="4"
                    v-model.trim="dataForm.inputNumber"
                    @change="valueName(dataForm.inputNumber)"
                    placeholder="请输入认证码"
                  ></el-input>
                  <div class="login_code" @click="refreshCode">
                    <SIdentify :identifyCode="identifyCode" />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose" size="small">重 置</el-button>
          <el-button type="danger" size="small" @click="clickSure"
            >确 定</el-button
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import SIdentify from "./Sidentify.vue";
import { Message } from "element-ui";
import { post } from "@/api/api";
import { ref, onMounted, reactive, defineEmits, defineProps } from "vue";

const props = defineProps({
  SMreport: { type: String },
});
let phonePass = (rule, value, callback) => {
  const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (value && !reg.test(value)) {
    callback("请输入正确的手机号");
  }
  callback();
};
let IDPass = (rule, value, callback) => {
  const reg =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  if (value && !reg.test(value)) {
    callback("请输入正确的身份证号");
  }
  callback();
};
const dataForm = reactive({
  name: "",
  idNumber: "",
  sexNum: "",
  company: "",
  duty: "",
  phone: "",
  cvname: "",
  cvsex: "",
  cvIdNumber: "",
  cvCompany: "",
  cvDuty: "",
  problem: "",
  inputNumber: "",
  documentFileList: [],
  radio: false,
  disabled: true,
  reportsex: "",
  UID: "",
});
const rules = reactive({
  name: [
    {
      required: true,
      message: "请输入名字",
      trigger: "blur",
    },
  ],
  idNumber: [
    {
      required: true,
      message: "请输入身份证号",
      trigger: "blur",
    },
    { validator: IDPass, trigger: "blur" },
  ],
  inputNumber: [
    {
      required: true,
      message: "请输入认证码",
      trigger: "blur",
    },
  ],
  company: [
    {
      required: true,
      message: "请输入单位",
      trigger: "blur",
    },
  ],
  duty: [
    {
      required: true,
      message: "请输入职务",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号码",
      trigger: "blur",
    },
    { validator: phonePass, trigger: "blur" },
  ],
  cvname: [
    {
      required: true,
      message: "请输入名字",
      trigger: "blur",
    },
  ],
  cvsex: [
    {
      required: true,
      message: "请输入性别",
      trigger: "blur",
    },
  ],
  cvCompany: [
    {
      required: true,
      message: "请输入单位",
      trigger: "blur",
    },
  ],
  cvDuty: [
    {
      required: true,
      message: "请输入职务信息",
      trigger: "blur",
    },
  ],
  problem: [
    {
      required: true,
      message: "请输入主要问题",
      trigger: "blur",
    },
  ],
});
const nmrules = reactive({
  inputNumber: [
    {
      required: true,
      message: "请输入认证码",
      trigger: "blur",
    },
  ],
  cvname: [
    {
      required: true,
      message: "请输入名字",
      trigger: "blur",
    },
  ],
  cvsex: [
    {
      required: true,
      message: "请输入性别",
      trigger: "blur",
    },
  ],
  cvCompany: [
    {
      required: true,
      message: "请输入单位",
      trigger: "blur",
    },
  ],
  cvDuty: [
    {
      required: true,
      message: "请输入职务信息",
      trigger: "blur",
    },
  ],
  problem: [
    {
      required: true,
      message: "请输入主要问题",
      trigger: "blur",
    },
  ],
});
const SexOptions = ref([
  {
    sexNum: "1",
    label: "男",
  },
  {
    sexNum: "2",
    label: "女",
  },
]);
const SexOptions1 = ref([
  {
    sexNum: "1",
    label: "男",
  },
  {
    sexNum: "2",
    label: "女",
  },
]);
//  认证码
const emit = defineEmits(["handClickSelect"], ["inputModel"],["changeBackGO"]);
const goblack = () => {
  emit("changeBackGO", 'change');
};
const valueName = (val) => {
  dataForm.inputNumber = val;
  if (val != identifyCode.value) Message.warning("请重新输入认证码");
  emit("inputModel", dataForm.inputNumber);
};
// 图形验证码
let identifyCodes = "1234567890abcdefghijklmnopqrstuvwxyz";
let identifyCode = ref("3212");

const randomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const makeCode = (o, l) => {
  for (let i = 0; i < l; i++) {
    identifyCode.value += o[randomNum(0, o.length)];
  }
};

const refreshCode = () => {
  identifyCode.value = "";
  makeCode(identifyCodes, 4);
};
// 文件上传
const handleRemove = (file, fileList) => {
  console.log(file, fileList);
  fileList &&
    fileList.length > 0 &&
    fileList.forEach((item, index) => {
      if (item.uid === file.uid) {
        fileList.splice(index, 1);
      }
    });
  dataForm.documentFileList = fileList;
};
const handlePreview = (file) => {
  //console.log(file, 1111);
};
const beforeUpload = (file) => {
  const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

  const whiteList = ["pdf", "doc", "docx", "jpg", "png","jpeg","PDF", "DOC", "DOCX", "JPG", "PNG","JPEG"];

  if (whiteList.indexOf(fileSuffix) === -1) {
    Message.error("上传文件只支持 pdf、doc、docx、docx、png、jpeg格式");
    return false;
  }

  const isLt20M = file.size / 1024 / 1024 < 20;

  if (!isLt20M) {
    Message.error("上传文件大小不能超过 20MB");
    return false;
  }

  dataForm.UID = file.uid;
  let fd = new FormData();
  fd.append("file", file);
  fd.append("uid", file.uid);
  const res = post("/api/importment", fd);
  if (res.code == 0) {
    let fileList = dataForm.documentFileList || [];
    fileList.push({
      uid: file.uid,
    });
    dataForm.documentFileList = fileList;
  }else{
    Message.error(res.msg);
  }
};
const changeSEX1 = (val) => {
  SexOptions.value.map((v) => {
    if (v.sexNum == val) return (dataForm.sexNum = v.label);
  });
};
const changeSEX2 = (val) => {
  SexOptions1.value.map((v) => {
    if (v.sexNum == val) return (dataForm.reportsex = v.label);
  });
};
// 重置
const handleClose = () => {
  dataRef.value.resetFields();
  refreshCode();
};
const dataRef = ref(null);
const promiseData = async () => {
  let postData = {
    ID: dataForm.UID,
    INFORMER: dataForm.name,
    SEX: dataForm.sexNum,
    INFORMERDEPT: dataForm.company,
    DUTY: dataForm.duty,
    IDNUMBER: dataForm.idNumber,
    PHONE: dataForm.phone,
    REPORTPERSON: dataForm.cvname,
    REPORTSEX: dataForm.reportsex,
    REPORTDEPT: dataForm.cvCompany,
    REPORTDUTY: dataForm.cvDuty,
    REPORTIDNUMBER: dataForm.cvIdNumber,
    PROBLEM: dataForm.problem,
  };
  const res = await post("/api/geren/add", postData);
  if (res.code === 0) {
    Message.success("提交成功");
    dataRef.value.resetFields();
    refreshCode();
  }else{
    Message.error(res.msg);
    refreshCode();
  }
};
// 提交
const clickSure = () => {
  dataRef.value.validate((val) => {
    if (val) {
      promiseData();
    }
  });
};
onMounted(() => {
  refreshCode();
});
</script>
<style lang="less">
.selsctView {
  width: 1200px;
  margin: 0 auto;
  .back_icon {
    cursor: pointer;
    display: inline-block;
    width: 61px;
    height: 32px;
    position: absolute;
    right: 20px;
    top: 15px;
    background: url("../assets/image/homePage/back.png");
    z-index: 1;
  }
  p {
    color: #000;
  }
  .el-select {
    width: 470px;
  }
  .dialog-footer {
    margin-left: 44%;
    display: flex;
  }
}
</style>
