<!-- eslint-disable max-len -->
<template>
  <div class="homePageView">
    <!-- <div class="homePageView_title"><p>临平区平时考核智慧系统</p></div> -->
    <h1 class="homePageView_title">
      <div></div>
    </h1>
    <div class="homePageView_main" v-if="data.I_reportData == ''">
      <div class="homePageView_main_left">
        <ul>
          <li
            v-for="(item, id) in data.tab_list"
            :key="id"
            @click="tabChange(item.id)"
            :class="data.tab_ID == item.id ? 'tabCk' : ''"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <pageIndex @I_report="I_report" v-if="data.tab_ID == 1"></pageIndex>
      <acceptanceIntroduction v-if="data.tab_ID == 2"></acceptanceIntroduction>
      <regulations v-if="data.tab_ID == 3"></regulations>
      <suggestions v-if="data.tab_ID == 4"></suggestions>
    </div>
    <div class="homePageView_main1" v-if="data.I_reportData == '我要举报'">
      <comeReport
        @realName="realName"
        @changeBack="changeBack"
        v-show="!data.realNameS && data.provincialCk"
      ></comeReport>
      <reportMap
        @provincial="provincial"
        @tabID="tabID"
        @mapName="mapName"
        v-show="!data.realNameS && !data.provincialCk"
      ></reportMap>
      <reportSec
        :mapName="data.mapName"
        @personal="personal"
        @changeBackAgree="changeBackAgree"
        v-show="data.realNameS && !data.toReport"
        :SMreport="data.realNameS"
      ></reportSec>
      <selectPersonal
        :SMreport="data.realNameS"
        @changeBackGO="changeBackGO"
        v-if="data.toReport == 1"
      ></selectPersonal>
      <selectCompany
        :SMreport="data.realNameS"
        @changeBackGOCom="changeBackGOCom"
        v-if="data.toReport == 2"
      ></selectCompany>
    </div>
    <div class="homePageView_footer">
      <div class="foot_content">
        <a
          class="foot_icon"
          href="http://bszs.conac.cn/sitename?method=show&id=0A51574F7EA44145E053012819AC7703"
          target="_blank"
          >
        /></a>
        <div class="font_cont">
          <p>Copyright 中共浙江省委组织部 技术支持：浙江浙大网新软件产业集团有限公司</p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >浙ICP备10053157号-2</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import pageIndex from "./components/pageIndex.vue";
import acceptanceIntroduction from "./components/acceptanceIntroduction.vue";
import regulations from "./components/regulations.vue";
import suggestions from "./components/suggestions.vue";
import comeReport from "./components/comeReport.vue";
import selectPersonal from "../../components/selectPersonal.vue";
import selectCompany from "../../components/selectCompany.vue";
import reportMap from "./components/reportMap.vue";
import reportSec from "./components/reportSec.vue";
import { reactive } from "vue";
import "./homePage.less";

const data = reactive({
  tab_list: [
    {
      id: 1,
      name: "首页",
    },
    {
      id: 2,
      name: "受理介绍",
    },
    {
      id: 3,
      name: "法规制度",
    },
    {
      id: 4,
      name: "意见建议",
    },
  ],
  tab_ID: 1,
  I_reportData: "",
  realNameS: "",
  toReport: "",
  provincialCk: "",
  mapName: "",
});
const tabChange = (val) => {
  data.tab_ID = val;
};
const tabID = (val) => {
  data.tab_ID = val;
  data.I_reportData = '';
};
const changeBack = () => {
  data.realNameS = '';
  data.provincialCk = '';
};
const changeBackAgree = () => {
  data.realNameS = '';
};
const changeBackGO = () => {
  data.toReport = '';
};
const changeBackGOCom = () => {
  data.toReport = '';
};
const I_report = (val) => {
  data.I_reportData = val;
};
const realName = (val) => {
  data.realNameS = val;
};
const personal = (val) => {
  data.toReport = val;
};
const provincial = (val) => {
  data.provincialCk = val
};
const mapName = (val) => {
  data.mapName = val;
};
</script>
