<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="comeReportView">
    <span class="back_icon" @click="goblack()"></span>
    <h2>欢迎进入浙江"12380"举报网</h2>
    <ul>
      <li v-for="(item, id) in data.argess_list" :key="id">
        <span></span><span>{{ item.name }}</span>
      </li>
    </ul>
    <h6>
      （为保证举报信息传输的安全，系统会自动弹出“安全警报”或“安全证书”对话框，请点击“是”或“继续浏览”，进行举报。）
    </h6>
    <div class="agree">
      <el-checkbox
        v-model="data.disabled"
        label="0"
        @change="clickRadio(data.disabled)"
        >同意上述条款</el-checkbox
      >
    </div>
    <div class="button">
      <el-button
        class="el-icon-user-solid"
        type="danger"
        :disabled="data.disabledBU == false"
        @click="routCK('1')"
        >实名举报</el-button
      >
      <el-button
        class="el-icon-lock"
        type="danger"
        :disabled="data.disabledBU == false"
        @click="routCK('2')"
        >匿名举报</el-button
      >
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, getCurrentInstance } from "vue";

const data = reactive({
  argess_list: [
    {
      id: 1,
      name: "本网站主要受理反映浙江省各级领导班子和领导干部违反《党政领导干部选拔任用工作条例》和有关法规选人用人问题及处级以上领导干部政治、思想、作风、廉洁自律等方面问题的举报。如果您反映其他方面的问题，请直接向有关部门反映。需要向各市委组织部举报的，请点击",
    },
    {
      id: 2,
      name: "请认真填写举报表单中的各项目。填写“主要问题”一项时，要写清楚问题发生的时间、地点、涉及人员、情节、后果等，字数限定在800字以内。",
    },
    {
      id: 3,
      name: "举报人应实事求是地反映问题。对利用举报捏造事实，进行诬告陷害的，依照有关规定严肃处理；构成犯罪的，移交司法机关处理。",
    },
    {
      id: 4,
      name: "提倡实名举报。进行实名举报时，请详细填写联系电话等项目。",
    },
    {
      id: 5,
      name: "本网站不提供举报信息反馈查询，如需了解举报的查处情况，可直接与中共浙江省委组织部举报中心联系，电话（0571）12380。",
    },
    {
      id: 6,
      name: "为保障举报人的合法权益，限制恶意重复举报和垃圾信件，提高网上举报的运行效益，举报人计算机每天最多只能提交3件举报。",
    },
  ],
  tab_ID: 1,
  disabled: false,
  disabledBU: false,
});
const emit = defineEmits(["I_report"],["changeBack"]);
const goblack = () => {
  emit("changeBack", 'change');
};
const { proxy } = getCurrentInstance();
const clickRadio = (val) => {
  if (val == true) {
    proxy
      .$confirm("安全警报, 是否继续?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "继续浏览",
        type: "warning",
      })
      .then(() => {
        data.disabledBU = true;
      })
      .catch(() => {
        data.disabledBU = false;
      });
  }
};
const routCK = (val) => {
  emit("realName", val);
};
</script>
<style lang="less" scoped>
.comeReportView {
  height: 100%;
  .back_icon {
    cursor: pointer;
    display: inline-block;
    width: 61px;
    height: 32px;
    position: absolute;
    right: 20px;

    background: url("../../../assets/image/homePage/back.png");
  }
  h2 {
    color: #bd050a;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
  }
  ul {
    li {
      color: #383838;
      font-size: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      & > span:first-child {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #eb6141;
        margin-right: 10px;
      }
      span {
        display: inline-block;
        width: 1190px;
      }
    }
  }
  h6 {
    font-size: 18px;
    font-weight: bold;
    color: #383838;
    line-height: 30px;
    letter-spacing: 1px;
    text-align: center;
  }
  .agree {
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }
  .button {
    width: 100%;
    text-align: center;
  }
}
</style>
