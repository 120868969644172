import { render, staticRenderFns } from "./Sidentify.vue?vue&type=template&id=2716aff9&scoped=true&"
import script from "./Sidentify.vue?vue&type=script&setup=true&lang=js&"
export * from "./Sidentify.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Sidentify.vue?vue&type=style&index=0&id=2716aff9&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2716aff9",
  null
  
)

export default component.exports