<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="suggestionsView">
    <div class="suggestionsView_right">
      <div class="right_bot">
        <h2><i class="i"></i>意见建议</h2>
        <p>欢迎您对网上举报工作提出宝贵的意见建议：</p>
        <div class="dialogShow">
          <div class="form_box">
            <el-form
              label-width="0"
              :model="dataForm"
              ref="dataRef"
              :rules="rules"
            >
              <el-row :span="24">
                <el-col :span="24">
                  <el-form-item label="" prop="problem">
                    <el-input
                      v-model.trim="dataForm.problem"
                      type="textarea"
                      :rows="10"
                      placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :span="24">
                <p>您的信息：</p>
              </el-row>
              <el-row :span="24">
                <el-col :span="6">
                  <el-form-item label="姓名" prop="name">
                    <el-input
                      v-model.trim="dataForm.name"
                      placeholder="请输入姓名"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系方式" prop="phone">
                    <el-input
                      maxlength="11"
                      v-model.trim="dataForm.phone"
                      placeholder="请输入联系方式"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="认证码" prop="inputNumber">
                    <el-input
                      style="margin-right: 10px"
                      maxlength="4"
                      v-model.trim="dataForm.inputNumber"
                      @change="valueName(dataForm.inputNumber)"
                      placeholder="请输入认证码"
                    ></el-input>
                    <div class="login_code" @click="refreshCode">
                      <SIdentify :identifyCode="identifyCode" />
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer"
            >>
            <el-button type="danger" size="small" @click="clickSure"
              >提 交</el-button
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SIdentify from "../../../components/Sidentify.vue";
import { Message } from "element-ui";
import { post } from "@/api/api";
import { ref, onMounted, reactive, defineEmits } from "vue";

let phonePass = (rule, value, callback) => {
  const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (value && !reg.test(value)) {
    callback("请输入正确的手机号");
  }
  callback();
};
const dataForm = reactive({
  name: "",
  phone: "",
  inputNumber: "",
  problem: "",
});
const rules = reactive({
  name: [
    {
      required: true,
      message: "请输入名字",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号码",
      trigger: "blur",
    },
    { validator: phonePass, trigger: "blur" },
  ],
  problem: [
    {
      required: true,
      message: "请输入主要问题",
      trigger: "blur",
    },
  ],
  inputNumber: [
    {
      required: true,
      message: "请输入认证码",
      trigger: "blur",
    },
  ],
});

//  认证码
const emit = defineEmits(["handClickSelect"], ["inputModel"]);
const valueName = (val) => {
  dataForm.inputNumber = val;
  if (val != identifyCode.value) Message.warning("请重新输入认证码");
  emit("inputModel", dataForm.inputNumber);
};
// 图形验证码
let identifyCodes = "1234567890abcdefghijklmnopqrstuvwxyz";
let identifyCode = ref("3212");

const randomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const makeCode = (o, l) => {
  for (let i = 0; i < l; i++) {
    identifyCode.value += o[randomNum(0, o.length)];
  }
};

const refreshCode = () => {
  identifyCode.value = "";
  makeCode(identifyCodes, 4);
};
const dataRef = ref(null);

const promiseData = async () => {
  let postData = {
    NAME: dataForm.name,
    PHONE: dataForm.phone,
    SUGGESTION: dataForm.problem,
  };
  const res = await post("/api/Suggest/add", postData);
  if (res.code === 0) {
    Message.success("提交成功");
    dataRef.value.resetFields();
    refreshCode();
  }else{
    Message.error(res.msg);
    refreshCode();
  }
};
// 提交
const clickSure = () => {
  dataRef.value.validate((val) => {
    if (val) {
      promiseData();
    }
  });
};
onMounted(() => {
  refreshCode();
});
</script>
<style lang="less" scoped>
.suggestionsView {
  display: flex;
  height: 100%;
}
.suggestionsView_right {
  width: 1082px;
  height: 610px;
  background: linear-gradient(
    180deg,
    rgba(253, 252, 249, 0.92) 0%,
    #f9f1f1 100%
  );
  border-radius: 8px;
  opacity: 0.8;
  backdrop-filter: blur(5px);
  padding: 29px;
  .right_bot {
    h2 {
      color: #bd050a;
      line-height: 34px;
      letter-spacing: 1px;
      line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 24px;
      i {
        display: inline-block;
        width: 6px;
        height: 29px;
        background: #eb6141;
        margin-right: 15px;
      }
    }
    p {
      color: #383838;
      font-size: 18px;
      text-indent: 23px;
      line-height: 50px;
    }
  }
  .dialog-footer {
    margin-left: 44%;
    display: flex;
  }
}
</style>
