<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="reportSecView">
    <span class="back_icon" @click="goblack()"></span>
    <h2>欢迎进入浙江"12380"举报网</h2>
    <P><i></i>您已选择向 浙江省委组织部 提交举报</P>
    <div class="p">您正在向{{ props.mapName }}委组织部提交举报</div>
    <div class="button">
      <el-button class="el-icon-user-solid" type="danger" @click="peportCk('1')"
        >我要举报个人</el-button
      >
      <el-button
        class="el-icon-office-building"
        type="danger"
        @click="peportCk('2')"
        >我要举报单位</el-button
      >
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
const props = defineProps({
  mapName: { type: String },
});
const emit = defineEmits(["personal"],["changeBackAgree"]);
const goblack = () => {
  emit("changeBackAgree", 'change');
};
const peportCk = (val) => {
  emit("personal", val);
};
</script>
<style lang="less" scoped>
.reportSecView {
  height: 100%;
  .back_icon {
    cursor: pointer;
    display: inline-block;
    width: 61px;
    height: 32px;
    position: absolute;
    right: 20px;

    background: url("../../../assets/image/homePage/back.png");
  }
  h2 {
    color: #bd050a;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
  }
  p {
    color: #696969;
    line-height: 34px;
    letter-spacing: 1px;
    line-height: 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    background-color: #fff;
    padding-left: 10px;
    i {
      display: inline-block;
      width: 6px;
      height: 29px;
      background-color: #eb6141;
      margin-right: 15px;
    }
  }
  .p {
    font-size: 24px;
    font-weight: bold;
    color: #c31c1f;
    line-height: 150px;
    letter-spacing: 1px;
    text-align: center;
  }
  .button {
    width: 100%;
    text-align: center;
  }
}
</style>
