<template>
  <div class="pageIndexView">
    <div class="pageIndexView_right">
      <p>
        欢迎进入中共浙江省委组织部“12380”举报网站。本网站主要受理反映浙江省各级领导班子和领导干部违反《党政领导干部选拔任用工作条例》和有关法规选人用人问题及处级以上领导干部政治、思想、作风、廉洁自律等方面问题的举报。
      </p>
      <p>感谢您对我们的信任和支持！</p>
      <div class="button" @click="report_click('我要举报')">我要举报</div>
      <div class="right_bot">
        <h2><i class="i"></i>其他部门举报网站</h2>
        <ul>
          <li
            v-for="(item, id) in data.router_list"
            :key="id"
            @click="router_jump(item.website)"
          >
            <i></i><span class="a_box" v-html="item.name"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits } from "vue";

const data = reactive({
  router_list: [
    {
      id: 1,
      name: `如果您要反映违反党的纪律问题,请向 <a>浙江纪委监委“12388”举报网站</a> 进行举报。`,
      website: "http://zhejiang.12388.gov.cn/m/jbcx.html",
    },
    {
      id: 2,
      name: "如果您要反映个人信访等问题,请进入 <a>浙江信访局网站</a> 进行办理。",
      website: "http://www.zjsxfj.gov.cn/",
    },
    {
      id: 3,
      name: "如果您要反映涉法涉诉等问题,请向 <span>人民检察院“12309”或人民法院“12368”举报电话</span> 进行举报。",
      website: "",
    },
    {
      id: 4,
      name: "如果您要反映土地征用、房屋拆迁及房产纠纷等问题,请向 <span>自然资源部门“12336”举报电话</span> 进行举报。",
      website: "",
    },
    {
      id: 5,
      name: "如果您要反映土地、山林、水利权属纠纷等问题,请向 <a>自然资源部门“12336”或农业部门“12316”举报网站</a> 进行举报。",
      website: "https://zrzyt.zj.gov.cn/",
    },
    {
      id: 6,
      name: "如果您要反映劳动用工维权等问题，请向 <span>浙江人力资源和社会保障厅“12333”举报电话</span> 进行举报。",
      website: "",
    },
  ],
});
const emit = defineEmits(["I_report"]);
const report_click = (val) => {
  emit("I_report", val);
};
const router_jump = (val) => {
  if (val !== "") {
    window.open(val, "_blank");
  }
};
</script>
<style lang="less" scoped>
.pageIndexView {
  display: flex;
  height: 100%;
  .pageIndexView_right {
    width: 986px;
    height: 613px;
    background: url(../../../assets/image/homePage/index_bg.png);
    padding: 29px;
    p {
      color: #bd050a;
      line-height: 34px;
      letter-spacing: 1px;
      font-size: 24px;
      text-indent: 50px;
      margin-bottom: 24px;
    }
    .button {
      width: 286px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      background: url(../../../assets/image/homePage/button.png);
      margin: 0 auto;
      cursor: pointer;
    }
    .right_bot {
      margin-top: 60px;
      h2 {
        color: #bd050a;
        line-height: 34px;
        letter-spacing: 1px;
        line-height: 50px;
        display: flex;
        align-items: center;
        font-size: 24px;
        i {
          display: inline-block;
          width: 6px;
          height: 29px;
          background-color: #eb6141;
          margin-right: 15px;
        }
      }
      ul {
        li {
          font-size: 20px;
          color: #383838;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          i {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #eb6141;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
