<template>
  <div id="map" ref="map"></div>
</template>
<script setup lang="ts">
import * as echarts from "echarts";
import { ref, onMounted, defineEmits, reactive } from "vue";
import { post } from "@/api/api";
const zhejiangMap = require("../map/zhejiang.json");

const initMapFn = () => {
  return {
    tooltip: {
      show: false,
      backgroundColor: "rgba(13, 39, 115, 0.5)",
      formatter: (params) => {
        let dataStr = `<div ><p style="font-size: 16px;
font-family: shishangzhonghei;
font-weight: 500;
color: #fff;
line-height: 20px;padding:0 5px 5px;">
    
       浙江援${params.name}:${params.value}人
   
    
      </p>
      <p style="font-size: 16px;
font-family: shishangzhonghei;
font-weight: 500;
color: #fff;
line-height: 20px;padding:0 5px 5px;">
    
       ${params.name}来浙挂职:${params.value}人
   
    
      </p>
      </div>`;
        return dataStr;
      },
    },
    geo: {
      map: "zhejiang",
      aspectScale: 1.5,
      layoutCenter: ["50%", "45%"],

      layoutSize: "70%",
      itemStyle: {
        areaColor: {
          type: "linear-gradient",
          x: 0,
          y: 1200,
          x2: 1000,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(255,255,255,0)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(255,255,255,0)", // 50% 处的颜色
            },
          ],
          global: true, // 缺省为 false
        },
        shadowColor: "#0f5d9d",
        shadowOffsetX: 0,
        shadowOffsetY: 15,
        opacity: 0.5,
      },
      emphasis: {
        areaColor: "#0f5d9d",
      },
    },
    series: [
      {
        type: "map",
        // selectedMode: "multiple",
        map: "zhejiang",
        aspectScale: 0.8,
        layoutCenter: ["50%", "50%"], //地图位置
        layoutSize: "100%",
        zoom: 1, //当前视角的缩放比例
        // roam: true, //是否开启平游或缩放
        scaleLimit: {
          //滚轮缩放的极限控制
          min: 1,
          max: 2,
        },
        label: {
          // 地图字体
          show: true,
          color: "#FFFFFF",
          fontSize: 12,
        },
        itemStyle: {
          areaColor: "RGB(215,78,50)", // 地区颜色
          borderColor: "RGB(228,144,91)",
          borderWidth: 1.8,
        },
        // 鼠标经过高亮显示
        emphasis: {
          itemStyle: {
            areaColor: "RGB(216,157,93)", // 地区颜色
            borderColor: "RGB(228,144,91)",
            borderWidth: 3,
          },
          label: {
            show: true,
            color: "#000",
            fontSize: 16,
            fontWeight: "500",
          },
        },
        select: {
          //选中字体颜色
          label: {
            color: "#fff",
            fontSize: 16,
            fontWeight: "500",
          },
          itemStyle: {
            areaColor: "RGB(216,157,93)", // 地区颜色
            borderColor: "#86ffef",
            borderWidth: 1.8,
          },
        },
        data: [],
      },
    ],
  };
};
const mapLink = reactive({
  value: [],
});
// const mapLink = ref([
//   {
//     name: "杭州市",
//     link: "http://12380.hz.gov.cn/",
//   },
//   {
//     name: "宁波市",
//     link: "http://ww.zjnb12380.gov.cn/",
//   },
//   {
//     name: "温州市",
//     link: "https://www.zjwz12380.gov.cn/",
//   },
//   {
//     name: "嘉兴市",
//     link: "http://www.zjjx12380.gov.cn/",
//   },
//   {
//     name: "湖州市",
//     link: "http://www.zjhz12380.gov.cn/",
//   },
//   {
//     name: "绍兴市",
//     link: "https://www.zjsx12380.gov.cn/",
//   },
//   {
//     name: "金华市",
//     link: "http://www.zjjh12380.gov.cn/",
//   },
//   {
//     name: "舟山市",
//     link: "http://www.zjzs12380.gov.cn/",
//   },
//   {
//     name: "台州市",
//     link: "https://www.zjtz12380.gov.cn/",
//   },
//   {
//     name: "衢州市",
//     link: "http://12380.qz.gov.cn/",
//   },
//   {
//     name: "丽水市",
//     link: "http://www.zjls12380.gov.cn/",
//   },
// ]);
const map = ref();
const emit = defineEmits(["indexEmitFn"]);
const initMap = () => {
  const mychart = echarts.init(document.querySelector(`#map`));
  echarts.registerMap("zhejiang", { geoJSON: zhejiangMap });
  mychart.setOption(initMapFn());
  mychart.on("click", (params) => {
    emit("indexEmitFn", params);
    mapLink.value.map((v) => {
      if (v.dictLabel == params.name) window.open(v.dictValue, "_blank");
    });
  });
};
const getMapLink = async () => {
  let res = await post(`/api/mapList`, "");
  if (res.code == 0) {
    mapLink.value = res.data;
  }
};
onMounted(() => {
  initMap();
  getMapLink();
});
</script>
<style lang="less" scoped>
#map {
  width: 1200px;
  height: 340px;
}
</style>