<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="comeReportView">
    <span class="back_icon" @click="goblack()"></span>
    <h2>欢迎进入浙江"12380"举报网</h2>
    <P><i></i>您要向浙江省委组织部举报，请点击以下链接进入：</P>
    <div class="button">
      <el-button type="danger" @click="toCommittee('Committee')"
        >浙江省委组织部</el-button
      >
      <!-- {{ state.mapName }} -->
    </div>
    <P><i></i>您要向市委组织部举报，请点击以下链接进入：</P>
    <div class="zj_map">
      <ZjMap @indexEmitFn="indexEmitFn" />
    </div>
  </div>
</template>
<script setup>
import { defineEmits, reactive } from "vue";
import ZjMap from "../../../components/zjMap.vue";

const state = reactive({
  mapName: "浙江省",
});

const indexEmitFn = (obj) => {
  state.mapName = obj.name;
};

const emit = defineEmits(["provincial"], ["mapName"],["tabID"]);
const goblack = () => {
  emit("tabID", 1);
};
const toCommittee = (val) => {
  emit("provincial", val);
  emit("mapName", state.mapName);
};
</script>
<style lang="less" scoped>
.comeReportView {
  height: 100%;
  .back_icon {
    cursor: pointer;
    display: inline-block;
    width: 61px;
    height: 32px;
    position: absolute;
    right: 20px;

    background: url("../../../assets/image/homePage/back.png");
  }
  h2 {
    color: #bd050a;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
  }
  p {
    color: #bd050a;
    line-height: 34px;
    letter-spacing: 1px;
    line-height: 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    i {
      display: inline-block;
      width: 6px;
      height: 29px;
      background-color: #eb6141;
      margin-right: 15px;
    }
  }
  .button {
    margin: 38px auto;
    text-align: center;
  }
}
</style>
