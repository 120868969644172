<template>
  <div class="acceptanceIntroductionView">
    <div class="acceptanceIntroduction_right">
      <div class="right_bot">
        <h2><i class="i"></i>受理介绍</h2>
        <ul>
          <li v-for="(item, id) in data.router_list" :key="id">
            <i></i><span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";

const data = reactive({
  router_list: [
    {
      id: 1,
      name: "中共浙江省委组织部负责受理本网站的举报，主要受理反映浙江省各级领导班子和领导干部违反《党政领导干部选拔任用工作条例》和有关法规选人用人问题及处级以上领导干部政治、思想、作风、廉洁自律等方面问题的举报。",
      website: "www.baidu.com",
    },
    {
      id: 2,
      name: "中共浙江省委组织部本着 “分级负责、归口办理、认真调查、严肃处理”的原则，慎重负责地处理网上举报件。",
      website: "www.taobao.com",
    },
    {
      id: 3,
      name: "对受理范围内的举报，根据被举报对象的职级和举报反映的问题，由中共浙江省委组织部办理或督促有关地方、单位组织人事部门承办。其中，对群众反映的性质严重、内容具体、线索清楚的选人用人问题，中共浙江省委组织部进行立项督查，要求承办单位在规定时限内报告查核处理结果。",
      website: "",
    },
    {
      id: 4,
      name: "对经查问题属实的，依据有关规定，坚决纠正、严格问责；严重违反规定、已构成违纪违法的，移交执纪执法机关处理。",
      website: "",
    },
    {
      id: 5,
      name: "为保护举报人，中共浙江省委组织部制定了严格的保密制度，严防失密、泄密。对打击报复举报人的行为，将严肃追究有关人员的责任。",
      website: "",
    },
  ],
  tab_ID: 1,
});
</script>
<style lang="less" scoped>
.acceptanceIntroduction {
  display: flex;
  height: 100%;
}
.acceptanceIntroduction_right {
  width: 1082px;
  height: 610px;
  background: linear-gradient(
    180deg,
    rgba(253, 252, 249, 0.92) 0%,
    #f9f1f1 100%
  );
  border-radius: 8px;
  opacity: 0.8;
  backdrop-filter: blur(5px);
  padding: 29px;
  .right_bot {
    h2 {
      color: #bd050a;
      line-height: 34px;
      letter-spacing: 1px;
      line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 24px;
      i {
        display: inline-block;
        width: 6px;
        height: 29px;
        background: #EB6141;
        margin-right: 15px;
      }
    }
    ul {
      li {
        font-size: 20px;
        color: #383838;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #EB6141;
          margin-right: 15px;
        }
        span{
          display: inline-block;
          width: 1060px;
        }
      }
    }
  }
}
</style>
