import axios from 'axios';
import { Message } from 'element-ui';

const request = axios.create({
  baseURL: '',
  timeout: 10000,
});
// 添加请求拦截器
request.interceptors.request.use((config) => {
  return config;
}, (error) => Promise.reject(error));

// // 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    const code = response.data.code;
    if (code !== 0) {
      Message.error(res.message)
      return Promise.reject(new Error(res.message))
    } else {
      return res
    }
  },
  (error) => {
    if (error.message.indexOf('timeout') != -1) {
      Message.error('网络超时');
    } else if (error.message == 'Network Error') {
      Message.error('网络连接错误');
    } else {
      if (error.response.data) Message.error(error.response.statusText);
      else Message.error('接口路径找不到');
    }
    return Promise.reject(error);
  }
);


export const get = (url, params) => request.get(url, { params });
// 发起一个post请求封装
export const post = (url, data) => request.post(url, data);

export function fileParams(data) {
  return request({
    url: '/api/importment',
    method: 'POST',
    target: '#form-loading',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
  })
}